import { createWebHistory, createRouter } from 'vue-router';
const MarketingApp = () => import('@/components/MarketingApp.vue');
// map of what a route object looks like
// type RouteData = {
//     path: string,
//     name: string,
//     component: typeof MarketingApp,
//     redirect?: string,
//     query?: string,
//     hash?: string,
//     meta?: any, // title, metaTags
//     props?: object,
//     children?: object[],
// };
const routes = [
    {
        path: '/',
        name: 'Start - Journalytics Medicine',
        component: MarketingApp,
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'Not Found',
        component: MarketingApp,
    },
];
function wait(duration) {
    return new Promise((resolve) => setTimeout(resolve, duration));
}
async function tryScrollToAnchor(hash, timeout = 600, delay = 150) {
    while (timeout > 0) {
        const el = document.querySelector(hash);
        if (el) {
            const view_offset = window.scrollY;
            const elementPosition = el.getBoundingClientRect().top;
            const offsetPosition = elementPosition + view_offset;
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
            break;
        }
        await wait(delay);
        timeout = timeout - delay;
    }
}
const router = createRouter({
    history: createWebHistory(),
    routes: routes,
    async scrollBehavior(to, from, savedPosition) {
        const selector = await to.hash;
        if (selector) {
            tryScrollToAnchor(to.hash, 1000, 100);
        }
        else if (savedPosition) {
            return savedPosition;
        }
        else {
            return { top: 0 };
        }
    },
});
export default router;
// generic example of auth checking in vue-router
//   router.beforeEach((to, from) => {
//     // instead of having to check every route record with
//     // to.matched.some(record => record.meta.requiresAuth)
//     if (to.meta.requiresAuth && !auth.isLoggedIn()) {
//       // this route requires auth, check if logged in
//       // if not, redirect to login page.
//       return {
//         path: '/login',
//         // save the location we were at to come back later
//         query: { redirect: to.fullPath },
//       }
//     }
//   })
