import { createStore } from 'vuex';
const store = createStore({
    state: {
        nav_title: '',
        nav_feed_prefix: '',
        nav_items: [],
        foot_items: [],
        popup_status: true,
    },
    getters: {
        navTitle: (state) => state.nav_title,
        navFeedPrefix: (state) => state.nav_feed_prefix,
        navItems: (state) => state.nav_items,
        footItems: (state) => state.foot_items,
        popupStatus: (state) => state.popup_status,
    },
    mutations: {
        setNavTitle(state, title) {
            state.nav_title = title;
        },
        setNavFeedPrefix(state, prefix) {
            state.nav_feed_prefix = prefix;
        },
        setNavItems(state, items) {
            state.nav_items = items;
        },
        setFootItems(state, items) {
            state.foot_items = items;
        },
        setPopupStatus(state, value) {
            state.popup_status = value;
        }
    },
    actions: {
        setNavTitle({ commit }) {
            commit('setNavTitle');
        },
        setNavFeedPrefix({ commit }) {
            commit('setNavFeedPrefix');
        },
        setNavItems({ commit }) {
            commit('setNavItems');
        },
        setFootItems({ commit }) {
            commit('setFootItems');
        },
        setPopupStatus({ commit }) {
            commit('setPopupStatus');
        }
    },
});
export default store;
