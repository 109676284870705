import { createApp } from 'vue';
import App from '@/App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueGtag from 'vue-gtag';
import CabellsDesignSystem from 'cabells-design-system';
import { getCmsApi } from './components/lib/UrlBase';
import WebAuth from "@auth0/auth0-spa-js";
import VueTippy from 'vue-tippy';
import 'tippy.js/dist/tippy.css';
const marked = require('marked');
const MarketingApp = () => import('@/components/MarketingApp.vue');
store.commit('setPopupStatus', true);
const nav_items = [];
let nav_title = '';
let nav_feed_prefix = '';
const foot_items = {};
const getNavigationLinks = async () => {
    try {
        // collect information from the strapi navigation information for the marketing menu bar
        const reply = await axios.get(getCmsApi('navigation/render/marketing-header'));
        let next_index = 0;
        for (const i in reply.data) {
            const item = reply.data[i];
            if (item.parent) {
                const new_nav_item = {
                    LinkDestination: item?.related?.Slug ?? item.path,
                    LinkText: item.title,
                    PageTitle: item?.PageTitle ?? item.path,
                    CrossSiteLink: item.CrossSiteLink,
                    ExternalPath: item?.externalPath,
                    OpenInNewTab: item?.OpenInNewTab,
                };
                for (const j in nav_items) {
                    const nav_item = nav_items[j];
                    if (nav_item.id === item.parent.id) {
                        nav_item.Link.push(new_nav_item);
                        break;
                    }
                }
            }
            else {
                const blank_any_array = [];
                const new_nav_item = {
                    Link: blank_any_array,
                    LinkDestination: item?.related?.Slug ?? item.path,
                    PageTitle: item.PageTitle,
                    CrossSiteLink: item.CrossSiteLink,
                    OnlyWhenAuthenticated: item.RequiresLogin,
                    Text: item.title,
                    id: item.id,
                    ExternalPath: item?.externalPath,
                    OpenInNewTab: item?.OpenInNewTab,
                };
                nav_items.push(new_nav_item);
                next_index += 1;
            }
        }
        store.commit('setNavItems', nav_items);
        nav_title = 'Cabells'; // nav header title (text next to the logo)
        store.commit('setNavTitle', nav_title);
        nav_feed_prefix = "our-news";
        store.commit('setNavFeedPrefix', nav_feed_prefix);
        // create the routes based on the items in strapi's navigation plugin
        for (const i in nav_items) {
            const slug = nav_items[i]?.LinkDestination ?? '';
            const route = {
                path: '/' + slug,
                name: nav_items[i]?.PageTitle ?? slug,
                meta: {
                    title: nav_items[i]?.PageTitle ?? slug,
                },
                component: MarketingApp,
            };
            if (slug == 'start') {
                route.redirect = '/';
            }
            router.addRoute(route);
            const subMenuItems = Array.isArray(nav_items[i].Link) && nav_items[i].Link.length > 0
                ? nav_items[i].Link
                : [];
            if (subMenuItems.length) {
                for (const j in subMenuItems) {
                    const subroute = {
                        path: '/' + subMenuItems[j]?.LinkDestination ?? '',
                        name: subMenuItems[j]?.PageTitle ?? subMenuItems[j]?.LinkDestination ?? '',
                        meta: {
                            title: subMenuItems[j]?.PageTitle ?? subMenuItems[j]?.LinkDestination ?? '',
                        },
                        component: MarketingApp,
                    };
                    router.addRoute(subroute);
                }
            }
        }
    }
    catch (e) {
        if (process.env.NODE_ENV != 'production') {
            console.error(e);
        }
        else {
            store.commit('setNavItems', [{
                    Link: [],
                    LinkDestination: "start",
                    PageTitle: "Cabells",
                    Text: "Start",
                    id: "000000000000000000000000",
                }]);
            store.commit('setNavTitle', "JOURNALYTICS MEDICINE");
        }
    }
};
const getFooterLinks = async () => {
    try {
        // we have a few details in the "footer information" api to collect
        const footer_info = await axios.get(getCmsApi('footer-information', 3));
        if (footer_info?.data?.data?.attributes?.Logo?.data?.[0]?.attributes?.url)
            foot_items.logo_url = footer_info.data.data.attributes.Logo.data[0].attributes.url;
        if (footer_info?.data?.data?.attributes?.LogoAltText)
            foot_items.logo_alt_text = footer_info.data.data.attributes.LogoAltText;
        if (footer_info?.data?.data?.attributes?.BaseText)
            foot_items.base_text = footer_info.data.data.attributes.BaseText;
        if (footer_info?.data?.data?.attributes?.Copywrite)
            foot_items.copywrite = footer_info.data.data.attributes.Copywrite;
        if (footer_info?.data?.data?.attributes?.SocialMediaButton?.length) {
            const social_buttons = [];
            for (const i of footer_info.data.data.attributes.SocialMediaButton) {
                const next_item = {};
                if (i?.Destination)
                    next_item.destination = i.Destination;
                if (i?.Icon)
                    next_item.icon = i.Icon;
                if (i?.Name)
                    next_item.name = i.Name;
                social_buttons.push(next_item);
            }
            foot_items.social_buttons = social_buttons;
        }
    }
    catch (e) {
        if (process.env.NODE_ENV != 'production') {
            console.error('footer info fail: ' + e);
        }
    }
    try {
        // this collects the links from the nav plugin's api
        const footer_items = await axios.get(getCmsApi('navigation/render/footer'));
        const foot_links = [];
        // while they have structure, the api returns them as a list
        // but they say if they have a parent or not, and if they do, it says which is their parent
        // the following turns those in to more of a structure
        // this only supports one level of depth though
        // the footer tool only supports that as well
        for (const f of footer_items.data) {
            if (f.parent) {
                for (const p of foot_links) {
                    if (f.parent.id == p.id) {
                        if (!p.children)
                            p.children = [];
                        p.children.push(f);
                        break;
                    }
                }
            }
            else {
                foot_links.push(f);
            }
        }
        foot_items.links = foot_links;
    }
    catch (e) {
        if (process.env.NODE_ENV != 'production') {
            console.error('footer links fail: ' + e);
        }
    }
    // both of those api calls data are put in to foot_items so now we put that in the store for all to see
    store.commit('setFootItems', foot_items);
};
getNavigationLinks().then(() => {
    getFooterLinks().then(() => {
        const SITE = createApp(App);
        // SITE.config.performance = true; // only works in dev mode
        SITE.config.globalProperties.window = window;
        SITE.config.globalProperties.document = document;
        SITE.config.globalProperties.console = console;
        SITE.config.globalProperties.axios = axios;
        SITE.config.globalProperties.marked = marked;
        SITE.use(VueTippy, {
            directive: 'tippy',
            component: 'tippy',
            componentSingleton: 'tippy-singleton',
            defaultProps: {
                placement: 'auto-end',
                allowHTML: true,
            }
        });
        SITE.use(CabellsDesignSystem).use(store);
        SITE.use(router);
        SITE.use(VueGtag, {
            enabled: false,
            deferScriptLoad: true,
            config: {
                id: process.env.VUE_APP_GTAG_ID,
            },
        }, router);
        if (process.env.VUE_APP_AUTH0_STATUS === 'enabled') {
            new WebAuth({
                domain: process.env.VUE_APP_AUTH_LOGIN_DOMAIN,
                audience: 'https://' + process.env.VUE_APP_AUTH_LOGIN_DOMAIN + '/api/v2/',
                client_id: process.env.VUE_APP_AUTH_LOGIN_CLIENT_ID,
                redirect_uri: process.env.VUE_APP_MARKETING_URL,
                response_type: 'token id_token',
            }).then((auth0) => {
                SITE.config.globalProperties.auth0 = auth0;
                SITE.mount('#app');
            }).catch((e) => {
                if (process.env.NODE_APP !== 'production') {
                    console.error(e);
                }
            });
        }
        else {
            SITE.mount('#app');
        }
    });
});
