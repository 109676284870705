const handleSlashes = (uri, env) => {
    if (uri && uri.length > 1 && !uri.startsWith('/') && !env?.endsWith('/')) {
        uri = '/' + uri;
    }
    return uri;
};
const getCmsUrl = (path, queries) => {
    let base = process.env.VUE_APP_CMS_API_URL ?? '';
    if (base.endsWith('/'))
        base = base.slice(0, -1);
    let path_string = path.join("/");
    if (path_string.startsWith('/'))
        path_string = path_string.slice(1);
    let q = "";
    if (queries) // change from {a:b, c:d ...} to be a=b&c=d...
        q = "?" + new URLSearchParams(queries).toString();
    return base + "/" + path_string + q;
};
const getCmsApi = (path, depth) => {
    if (depth)
        return getCmsUrl(['api', path], { 'populate': 'deep,' + depth });
    return getCmsUrl(['api', path]);
};
const getCmsApiBySlug = (path, slug, depth) => {
    if (depth)
        return getCmsUrl(['api', path], { "filters[slug][$eq]": slug, "populate": "deep," + depth });
    return getCmsUrl(['api', path], { "filters[slug][$eq]": slug });
};
const getCmsUrlOLD = (uri, slug, id) => {
    uri = handleSlashes(uri, process.env.VUE_APP_CMS_API_URL);
    let url = process.env.VUE_APP_CMS_API_URL + uri;
    if (slug && slug.length > 0) {
        url += '?slug=' + slug;
    }
    else if (id && id.length > 0) {
        id = handleSlashes(id, process.env.VUE_APP_CMS_API_URL);
        url += id;
    }
    return url;
};
const getNewsletterSignupApiUrl = (uri) => {
    uri = handleSlashes(uri, process.env.VUE_APP_NEWSLETTER_SIGNUP_API);
    return process.env.VUE_APP_NEWSLETTER_SIGNUP_API + uri;
};
const getApiHeaders = {
    headers: {
        'Content-Type': 'application/json',
    }
};
export { getCmsUrl, getCmsApi, getCmsApiBySlug, getNewsletterSignupApiUrl, getApiHeaders, };
