<template>
	<router-view :key="$route.path" name="default" />
</template>

<script>
	import 'cabells-design-system/src/assets/css/ds/1.0/twglobal.css';
	import screens from 'cabells-design-system/design-system-config/1.0.0/screens.config';
	import '@/assets/css/tooltips.css';
	import { computed } from 'vue';

	export default {
		name: 'App',
		mounted() {
			const utm_source = new URLSearchParams(location.search).getAll("utm_source");
			const utm_medium = new URLSearchParams(location.search).getAll("utm_medium");
			const utm_campaign = new URLSearchParams(location.search).getAll("utm_campaign");
			const d = new Date();
			d.setTime(d.getTime() + (7*24*60*60*1000)); // a week
			let expires = "expires="+ d.toUTCString();
			if (utm_source)
			{
				document.cookie = "utm_source=" + utm_source + ";" + expires + ";path=/";
			}
			if (utm_medium)
			{
				document.cookie = "utm_medium=" + utm_medium + ";" + expires + ";path=/";
			}
			if (utm_campaign)
			{
				document.cookie = "utm_campaign=" + utm_campaign + ";" + expires + ";path=/";
			}
			let i;
			for (i in screens) {
				const min = screens[i].min;
				const max = screens[i]?.max;
				let mediaQuery = '(min-width:' + min + ')';
				mediaQuery += (max) ? ' and (max-width:' + max + ')' : '';
				const mediaQueryList = window.matchMedia(mediaQuery);
				this.breakpointCallback(mediaQueryList, i);
				mediaQueryList.addEventListener('change', this.breakpointCallback.bind(mediaQueryList, mediaQueryList, i));
			}
		},
		provide() {
			return {
				currentBreakpoint: computed(() => this.currentBreakpoint),
			}
		},
		data() {
			return {
				currentBreakpoint: '',
			}
		},
		methods: {
			breakpointCallback(mediaQueryList, breakpointName) {
				if (mediaQueryList.matches) {
					this.currentBreakpoint = breakpointName;
				}
			},
		},
	}
</script>